import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Tabs, Tab, Alert } from 'react-bootstrap';

const SoloGuidesAD: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Artifact Dungeons</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_ad.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Artifact Dungeons</h1>
          <h2>Artifact Dungeons in Solo Leveling: Arise explained.</h2>
          <p>
            Last updated: <strong>22/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Artifact Dungeons" />
        <p>
          In this guide, we’ll cover the new Dungeon Bosses, discussing key
          mechanics and the countermeasures players can use to conquer the
          higher-tier stages. These bosses were introduced alongside enemies
          with two Elemental Weaknesses, though at the cost of also doubling
          their Elemental Resistances.
        </p>
        <Alert variant="primary">
          <p>
            DISCLAIMER: For the time being we will not be covering the “Proof of
            the Strong” challenge difficulties, as those are currently reserved
            to very endgame players and we expect them to become progressively
            easier and more attainable later in the game’s lifespan, as new
            progression systems and higher levels are released.
          </p>
        </Alert>
        <SectionHeader title="Encore Missions" />

        <p>
          Significantly more challenging than Instance Dungeons, the Encore
          Missions require players to build teams around complex mechanics.
          Failure to address these mechanics properly will likely prevent you
          from defeating the bosses.
        </p>
        <h5>The Scorching Lava Stone Guardian</h5>
        <p>
          Also referred to as the Lava Golem by the player base, this boss is
          similar to other golem-type bosses, featuring high damage reduction
          and slow but devastating attacks that can kill your Hunters in one or
          two hits. However, it is relatively straightforward when it comes to
          gimmicks. We’ll focus on the elemental-advantaged teams, as while it
          is possible to defeat the boss without this advantage, the task can be
          daunting even with a high level of investment.
        </p>
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs"
          className="nav-tabs"
        >
          <Tab eventKey="Team1" title={<> Wind Team</>} className="with-bg">
            <h5 className="endless"> Wind Team</h5>
            <StaticImage
              src="../../../images/solo/generic/ad_1.webp"
              alt="Guides"
            />
            <p>
              This team is easier to assemble, especially since Woo Jinchul is
              given to all players along with his weapon, and Mirei’s excellent
              damage output is a significant asset. The Wind team doesn’t rely
              on any complex strategies; proper timing is the key to handling
              the encounter.
            </p>
            <p>
              Start with Woo, aim to break the boss as quickly as possible. Next
              switch to your support Hunter, such as Min, Han Se-Mi, or even
              Meilin or Lee Bora and let them do their thing. Lastly, finish
              your rotation by bringing in Amamiya Mirei and dealing as much
              burst damage as possible before switching her out whenever the
              Boss becomes Untargetable. Destroying the Rock Pillars can make
              the fight easier by providing more room to move around but is not
              strictly necessary.
            </p>
            <p>
              {' '}
              The Wind team has a much higher Total Power and optimization,
              allowing it to three-star the encounter
            </p>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<> Dark(ish) Team</>}
            className="with-bg"
          >
            <h5 className="endless"> Dark(ish) Team</h5>
            <StaticImage
              src="../../../images/solo/generic/ad_2.webp"
              alt="Guides"
            />
            <p>
              This team is more challenging to pull off and requires either all
              Dark Hunters to deal significant damage or a very strong Silver
              Mane Baek (SMBaek) to exploit the %HP damage mechanics.
            </p>
            <p>
              In this setup - Lim offers high break and solid damage output, Lee
              Bora buffs all your Dark Hunters and deal damage even after being
              switched out. SMBaek can fully use his kit, especially since Bleed
              and other %HP-based damage effects are enabled. The main downsides
              of this team are the lack of burst damage and the high level of
              investment required.
            </p>
            <p>
              Lim could be replaced by Woo, and Bora could be replaced by Min,
              but at that point, you might as well opt for the Wind team
              instead.
            </p>
            <p>
              Dark team, with a Total Power slightly above the red threshold,
              will take longer. However, more investment or better gear for
              SMBaek could change this outcome significantly.
            </p>
          </Tab>
        </Tabs>
        <h5>The Pursuing Death Stalker</h5>
        <p>
          Commonly referred to as "The Scorpion" by most players (because, let’s
          be honest, it’s exactly that—no need for a long, complicated name),
          this boss presents more annoying gimmicks than the Golem, making
          certain Hunters essential unless your Total Power (TP) is
          significantly above the recommendation.
        </p>
        <p>
          The Scorpion’s main gimmick is its passive health regeneration, which
          heals it by a percentage of its maximum health continuously.
          Therefore, the most crucial aspect of team building for this fight is
          ensuring you have at least one source of the Unhealable debuff or heal
          reduction. Cha Hae-In or Yoo Soohyun are ideal for this role, as they
          also happen to be the best damage dealers against this boss. Min is an
          excellent supporter in this battle, especially since both primary
          damage dealers specialize in burst damage. While Meilin is also an
          option, she doesn’t pack as much of a punch as Min.
        </p>
        <p>
          As with most fights these days, having a Breaker is also essential.
          The Scorpion will frequently enter phases where it charges a powerful
          nuke and gains massive damage reduction, making Breaking the only way
          to stop it. It also buries itself under the sand, becoming
          untargetable before reappearing either at the players' feet or at a
          distance when preparing to charge its nuke. All of its attacks will at
          least stagger you unless you have super armor or a shield, making it
          even more frustrating to land your burst.
        </p>
        <p> Team Compositions</p>
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<> Fire-Light Team</>}
            className="with-bg"
          >
            <h5 className="endless"> Fire-Light Team</h5>
            <StaticImage
              src="../../../images/solo/generic/ad_3.webp"
              alt="Guides"
            />
            <p>
              While a mono Fire team Works best in killing the boss for those at
              high end by being able to constantly rotate through three powerful
              damage dealers (Emma being a sub DPS at high dupes/with her
              exclusive weapon), we found that a double Fire + Light team
              consisting of Emma, Min, and Yoo Soohyun is easier to build around
              while being more forgiving in play.
            </p>
            <p>
              This team follows a double support + DPS format, allowing for
              mistakes thanks to Min’s powerful heals and Emma’s shields. Emma
              can eventually be replaced by Go Gunhee, but in that case, it
              might be easier to switch to a mono Light team. The only downside
              of Yoo is, as usual, her inability to adjust her position easily
              and the length of her skills’ animations. Her ability to deal high
              burst damage at A1 however makes her a very efficient DPS,
              focusing on hyper buffing her and unleashing her Ultimate +
              Hellfire combo. Igris will be used as the team greatly benefits
              from dealing critical hits.
            </p>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<> Mono Light Team</>}
            className="with-bg"
          >
            <h5 className="endless"> Mono Light Team</h5>
            <StaticImage
              src="../../../images/solo/generic/ad_4.webp"
              alt="Guides"
            />
            <p>
              Mono Light teams have become very powerful with the addition of Go
              Gunhee. His and Cha’s ability to rapidly rotate through their
              entire kits during buff/debuff windows makes this team easy to use
              and fun, requiring a high number of actions per minute (APM) to
              pilot effectively.
            </p>
            <p>
              Mono Light teams have become very powerful with the addition of Go
              Gunhee. His and Cha’s ability to rapidly rotate through their
              entire kits during buff/debuff windows makes this team easy to use
              and fun, requiring a high number of actions per minute (APM) to
              pilot effectively.
            </p>
          </Tab>
        </Tabs>
        <SectionHeader title="Instance Dungeons" />
        <p>
          Here come the dungeons featuring Sun Jinwoo (SJW). Kargalgan might be
          a familiar face to most, and his kit will be similar to his story
          version. The Cursed Giant Eiberg, however, is a new addition to the
          game. Aside from his totem mechanics, he resembles what you’d expect
          from a fairly basic Souls game boss. Both bosses are manageable due to
          their double elemental weaknesses, making it possible to use very
          strong free-to-play (F2P) weapons or even stronger SSR ones with great
          effectiveness.
        </p>
        <h5>The Almighty Shaman, Kargalgan</h5>
        <StaticImage
          src="../../../images/solo/generic/ad_5.webp"
          alt="Guides"
        />
        <p>
          Weak to Wind and Dark, West Wind (WW) and the Shadow Scythe are
          premier options for anyone who hasn’t invested heavily in other SSR
          Dark weapons. For those with a powerful Plum Sword or Moonshadow, both
          are excellent choices, either as a duo or paired with one of the first
          two weapons mentioned.
        </p>
        <p>
          In terms of strategy, the most notable gimmicks are Kargalgan’s
          ability to gain a large shield and the appearance of totems that make
          him invulnerable while he channels a powerful spirit bomb—complete
          with arms raised and everything. Keep your powerful skills or Ultimate
          ready for this phase, which occurs around 30% HP.
        </p>
        <p>
          For supports, a Breaker with elemental advantage will significantly
          help in quickly breaking Kargalgan’s gauge and dealing substantial
          damage. Besides that, bringing two supporters to heal and buff SJW’s
          damage, as usual, will work well in this fight. If the shield becomes
          too troublesome, Dongsoo and Meilin are valid options (Meilin can be
          brought even if the shield isn’t an issue).
        </p>
        <h5>The Cursed Giant Eiberg</h5>
        <StaticImage
          src="../../../images/solo/generic/ad_6.webp"
          alt="Guides"
        />
        <p>
          This boss is a bit trickier since strong weapons of the required
          elements are harder to come by, and his gimmick of summoning two
          totems with special effects can waste a lot of time. After dealing
          enough damage to the boss, he becomes invulnerable and summons two
          totems in the same spots each time. The red totem slowly heals the
          Giant, while the blue one slowly saps all your mana until it is
          destroyed. It’s best to focus on destroying the blue totem first, as
          losing mana will severely reduce your damage output and in turn making
          it harder to break the totem. The boss will continue attacking, so try
          to dodge and use shadow step on the totem for a quick and efficient
          takedown.
        </p>
        <p>
          Aside from that, the boss isn’t complicated. He will jump around the
          map quite a bit and attack multiple times in a row, so be mindful of
          how you use your dashes.
        </p>
        <p>
          For weapons, the reliable Orb of Avarice is a must-have for anyone
          lacking highly invested SSR weapons like Phoenix Soul. The Huntsman is
          also good, thanks to its innate breaking abilities, but Radiru’s bow
          can also be used. It’s also possible to use Thetis Grimoire or even
          Plum Sword/Moonshadow if your goal is to super-buff with only one
          strong weapon like the Orb or Phoenix Soul.
        </p>
        <p>
          For supports, the same strategy as with Kargalgan applies: a strong
          Breaker with elemental advantage and any form of damage buffs will
          suffice.
        </p>
        <h5>Ice Witch Beste (NEW!)</h5>
        <p>
          One of the newest additions to the Artifact Dungeons alongside
          Kaisellin, Beste is a Fire Weak Boss with many mechanics, whose
          strength mostly lies in the sheer number of different attacks she can
          execute, both with and without obvious Warning Signs (like the Red
          Patterns). We’ll go over the Weapons and Skills to bring and explain
          why those are the better choices when considering Beste’s abilities.
        </p>
        <p>
          First let’s start with abilities that the Boss uses only once per
          encounter then move on to the rest of her skillset:
        </p>
        <ul>
          <li>
            Her first noteworthy ability is a medium ranged Aura, which inflicts
            stacks of Freeze debuff to Sung Jinwoo if he stays in the radius for
            too long. As such, it is advised to utilize at least one ranged
            weapon - Ideally Orb of Avarice or Phoenix Soul Bow, as both feature
            high burst damage and large range. Note: Most of Beste’s skills
            inflict a stack of Freeze when hit and after 5 stacks, Sung Jinwoo
            is frozen for a few seconds.
          </li>
          <li>
            Her second major ability is used when she reaches the ~40% HP
            threshold, she channels a powerful attack that can be only countered
            by Breaking her. This Break is not as demanding as usual Break
            checks, and can be fulfilled by a single hit from Fire Armor Break
            Rune allowing players to bring a pure damage or buffing Skill
            alongside for maximum efficiency.
          </li>
          <li>
            At ~50% HP threshold, she summons two Elite Elf Monsters - the
            Captain and the Ranger, enemies most players are used to by now.
            Their skills are similar to their presence in usual stages, with
            Rain of Arrows and Tornado being the only true inconvenient skills.
            They have a small health pool and can be quickly taken care of by
            powerful AOE skills such as the Orb’s or the Fire Vertical Arts. She
            will also use a buffing spell, enhancing both her and her summons
            stats.
          </li>
          <li>
            Failure to whittle her HP down fast enough will lead to her casting
            a powerful combo, starting with a Circular Area away from her,
            followed by area close to her and then in the distance once more,
            each time releasing a powerful burst of energy from the ground.
          </li>
        </ul>
        <p>
          Let’s move on to the rest of her skills, which are divided between
          abilities with a clear Warning Cue (i.e the famous Red Pattern) and
          skills with their own unique cues (i.e the Giant Snowflakes), in no
          particular order:
        </p>
        <ul>
          <li>
            At the start of the fight, and then often throughout, she will use
            one of her two long range skills: A Long & Wide Dragon or Three Long
            & Narrow Ice Shards Rain. Both are easy and straightforward to
            Dodge, and serve as excellent moves to enable your Shadow Step.
          </li>
          <li>
            At times she will make Giant Snowflakes appear next to Sung Jinwoo
            or his shadows quickly followed by Ice Spikes erupting from them,
            dealing some damage without any Stagger.
          </li>
          <li>
            Another frequently used skill is Homing Ice Spikes Wave, which lacks
            a clear warning indicator but takes a couple of seconds for her to
            cast. This skill initiates at her feet and moves towards Sung Jinwoo
            following him around for a few seconds. You can Dash into the spikes
            when near you to trigger Dodge and follow up with Shadow Step,
            generally taking you far enough to completely ignore the remainder
            of the attack.
          </li>
          <li>
            A few times during the fight, Beste summons a few Ice Dragonlings,
            these can be easily killed or ignored as they only throw small Ice
            Projectiles that will result in damage no higher than average Basic
            Attacks.
          </li>
          <li>
            Whenever you see a Red Circle around her, make sure to take a hike
            as she launches a powerful attack that serves as the first part of a
            particularly long and powerful chain of attacks. She will follow up
            with summoning Flower Buds in multiple places around the Map and on
            Sung Jinwoo’s position exploding them with a few seconds delay. Best
            to keep moving and avoid using any skill with long animation during
            this time.
          </li>
          <li>
            Lastly, whenever you see a very Thin & Long Red pattern, this a sign
            of Beste’s Dash Attack in that direction, inflicting damage and
            causing Knockback if in range. Not only that, but in her wake,
            multiple Ice Spikes will spread outwards hitting in a fan-shaped
            area larger than her own Aura. It's best advised to keep your
            distance and wait for her to arrive at her destination before
            approaching her, or Dodging in the split second she closes in on you
            and triggering Shadow Step as soon as she stops moving.
          </li>
        </ul>
        <p>
          With all that said, Beste isn’t too hard of a Boss and mostly requires
          the Player to move frequently and avoid spending all their Dash
          charges on damage buffing (Such as spamming all your Dashes to get the
          Plum Sword’s Damage Amplification). Speaking of which, Plum Sword, as
          well as Moonshadow remain excellent secondary weapons for this fight
          to further boost the damage from your main Fire weapon.
        </p>
        <p>
          When it comes to skills that can be brought alongside Armor Break, the
          Fire Vertical Arts is excellent thanks to its range and small
          Backdash, while the new Fire Sonic Stream will be incredible when
          paired with a highly invested Phoenix Soul, greatly capitalizing on
          the already exceptional Core Attack damage from the weapon.
        </p>
        <p>
          For the main Shadow, the new Golem does wonders with the Orb of
          Avarice, while Tusk remains as top pick if playing the Phoenix Soul.
          Cerberus and Iron remain the best secondary shadows thanks to their
          overall damage increase and burst potential.
        </p>
        <p>
          The Supports for this fight will vary depending on your needs, as
          Beste constantly attacks you, thus at least one source of Healing is
          welcome, and since her Break mechanic is easy to interrupt - no
          Breaker is needed, allowing you to go for more damage, or simply a
          Hunter with high TP to get you closer to the recommended power.
        </p>
        <p>
          Here is a build example for Sung Jinwoo, with unoptimized gear and
          overall low Total Power (for the Tier 21, Challenge Lv0) and a lvl40
          Orb of Avarice:
        </p>
        <StaticImage
          src="../../../images/solo/generic/ad_7.webp"
          alt="Guides"
        />
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesAD;

export const Head: React.FC = () => (
  <Seo
    title="Artifact Dungeons | Solo Leveling: Arise | Prydwen Institute"
    description="Artifact Dungeons in Solo Leveling: Arise explained."
    game="solo"
  />
);
